import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import { Box } from '@3pc/layout-components-react';
import styled from '@emotion/styled';
import { devices } from '../styles/breakpoints';
import { colors } from '../styles/colors';

const PageTitle = styled.h1`
  margin: 30px auto;
  font-size: 2.5em;
  line-height: 2.813em;

  @media ${devices.mobileMin} {
    margin: 40px auto;
    font-size: 2.813em;
    line-height: 3.125em;
  }

  @media ${devices.tabletMin} {
    margin: 50px auto;
    font-size: 3.125em;
    line-height: 3.438em;
  }
`;

const PageText = styled.p`
  margin-bottom: 30px;
  font-size: 1em;
  line-height: 1.25em;

  @media ${devices.mobileMin} {
    padding: 0 10%;
    margin-bottom: 40px;
    font-size: 1.188em;
    line-height: 1.438em;
  }

  @media ${devices.tabletMin} {
    padding: 0 20%;
    margin-bottom: 50px;
    font-size: 1.375em;
    line-height: 1.625em;
  }
`;

const PageLinks = styled.li`
  &:first-of-type {
    margin-bottom: 20px;

    @media ${devices.mobileMin} {
      margin-right: 30px;
      margin-bottom: 0;
    }

    @media ${devices.tabletMin} {
      margin-right: 50px;
    }
  }

  a {
    display: inline-block;
    color: ${colors.blue};
    font-size: 1.375em;
    line-height: 1.625em;
    transition: all 200ms ease-in;
    border: 2px solid transparent;
    font-style: italic;

    @media ${devices.mobileMin} {
      font-size: 1.563em;
      line-height: 1.875em;
    }

    @media ${devices.tabletMin} {
      font-size: 1.875em;
      line-height: 2.125em;
    }

    @media ${devices.nonTouchable} {
      &:hover {
        text-decoration: underline;
        color: ${colors.blue};
      }
    }

    &:focus-visible {
      text-decoration: underline;
      color: ${colors.blue};
    }

    &:active {
      color: ${colors.black};
    }

    &:focus {
      outline: 0;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Box textAlign="center" mt="10%" padding={['0 20px', '0 50px', '0 70px']}>
        <PageTitle>404 - Seite nicht gefunden!</PageTitle>
        <PageText>
          Es tut uns leid, dass wir Ihnen die angeforderte Seite nicht anzeigen
          können. Möglicherweise ist dieser Inhalt umgezogen oder Sie sind auf
          einen fehlerhaften Link gestoßen. Bitte besuchen Sie unsere Startseite
          oder nutzen Sie die Suche, um den gewünschten Inhalt zu finden.
        </PageText>
        <Box
          as="ul"
          display={['block', 'flex']}
          textAlign="center"
          justifyContent="center"
        >
          <PageLinks as="li" mr="50px">
            <Link to="/">Link zur Startseite</Link>
          </PageLinks>
          <PageLinks as="li">
            <Link to="/suche">Link zur Suche</Link>
          </PageLinks>
        </Box>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
